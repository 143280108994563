.energyDetails {
  display: flex;
  flex-direction: column;

  @media (--tablet) {
    flex-direction: row;
  }

  & .energyDetailsRow {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-1);
    border: none;
    padding: 0;

    @media (--tablet) {
      flex: 1 auto;

      &:not(:last-child) {
        border-right: 1px solid var(--color-tarmac-grey-300);
        padding-right: var(--spacing-6);
      }
    }

    @media (--tablet-l) {
      flex: 2 auto;
    }

    & .energyDetailsItem {
      display: flex;
      justify-content: space-between;
      gap: var(--spacing-5);

      & .energyDetailsValue {
        white-space: nowrap;
      }
    }

    & .extraGap {
      padding-top: var(--spacing-2);
    }
  }

  & .co2EmissionRow {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: var(--spacing-6);
    gap: var(--spacing-1);

    @media (--tablet) {
      flex: 1 auto;
      padding-left: var(--spacing-6);
      padding-top: 0;
    }

    & .co2Emission {
      display: flex;
      align-items: flex-start;
      padding-top: var(--spacing-3);

      & .co2EmissionImage {
        position: relative;
        width: 140px;
        height: 160px;

        &[data-has-depleted-class='true'] {
          margin-top: var(--spacing-10);
        }

        &:before {
          content: '';
          position: absolute;
          width: 140px;
          height: 160px;
          background-image: url('./Images/emission_values-2x.svg');
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      & .co2EmissionDetails {
        display: flex;
        flex-direction: row;
        gap: var(--spacing-3);

        & .co2EmissionClassRow {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          text-align: right;
          position: relative;
          width: 79px;
          padding: 0;
          height: 200px;

          &:only-child {
            height: 100%;
          }

          &:not(:last-child) {
            border-right: 1px solid var(--color-tarmac-grey-300);
            padding-right: var(--spacing-3);
          }

          & .co2EmissionLabel {
            line-height: normal;
          }

          & .co2EmissionArrow {
            position: absolute;
            width: 50px;
            height: 18px;
            padding: 1px 0 0 22px;
            background-image: url('./Images/rainbow-chart-arrow-tarmacgray-a-3xplus.svg');
            background-size: contain;
            background-repeat: no-repeat;
            color: var(--color-championship-white);
            font-size: 10px;
            line-height: 1.4;
            text-align: left;
            top: var(--arrow-position, 0);

            &.class-A {
              --base-position: 0px;
            }
            &.class-B {
              --base-position: 24px;
            }
            &.class-C {
              --base-position: 48px;
            }
            &.class-D {
              --base-position: 72px;
            }
            &.class-E {
              --base-position: 96px;
            }
            &.class-F {
              --base-position: 119px;
            }
            &.class-G {
              --base-position: 145px;
            }

            --arrow-position: var(--base-position);

            &[data-has-depleted-class='true'] {
              --arrow-position: calc(var(--base-position) + 40px);
            }
          }
        }
      }
    }
  }
}
