.energySummary {
  display: flex;
  gap: var(--spacing-3);
  flex-direction: column;

  & .energySummaryItem {
    display: flex;
    gap: var(--spacing-5);
    justify-content: space-between;

    & .energySummaryValue {
      white-space: nowrap;
    }
  }

  & .priceSummary {
    display: flex;
    gap: var(--spacing-1);
    flex-direction: column;
  }
}
