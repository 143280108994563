.cardWrapper {
  &[data-alignment='right'],
  &[data-alignment='left'] {
    display: flex;
    flex-direction: column;
    @media (--tablet) {
      gap: var(--spacing-6);
    }
    & .imageContainer {
      aspect-ratio: 16 / 9;
      height: 100%;
      margin-bottom: var(--spacing-6);
      @media (--tablet) {
        height: 240px;
        width: 240px;
        margin: 0;
      }
    }
  }

  &[data-alignment='right'] {
    @media (--tablet) {
      flex-direction: row-reverse;
    }
  }
  &[data-alignment='left'] {
    @media (--tablet) {
      flex-direction: row;
    }
  }

  & .imageContainer {
    height: 168px;
    margin-bottom: var(--spacing-3);
    position: relative;
  }

  & .contentContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-3);
    text-align: left;

    & h5 {
      margin: 0;
      line-height: normal;
    }
  }
}
